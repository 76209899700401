body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.textcolor{
  font: bold 13px sans-serif !important;
  stroke-width:2;
}
div.tooltip-donut {
  position: absolute;
  text-align: center;
  padding: .5rem;
  background: white;
  color: black;
  border: 1px solid #313639;
  border-radius: 8px;
  pointer-events: none;
  font-size: .8rem;
  max-width: 50%;
}
