.row{
    margin-left: 0px !important;
}
.navbar{
    background-color:#67eea6 !important;
}
.char_list_container{
    background-color: rgb(238, 184, 76,.4);
}
.char_list_container2{
    background-color: #fafafa;
    overflow-y: scroll !important;
    margin-top: 0px!important;
    margin-left: 0px!important;
    padding-top: 0px;
    padding-bottom: 6px;
    max-height: 20vh;
}
.all_chars{
    padding: 0px 3px;
    margin:0px 1px;
    margin-left: 6px;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid black;
    color: rgb(0, 0, 0);
    font-size: .7em;
}
.all_chars:hover{
    background-color:#1c2aee;
}
.hview{
    background-color:#e3e9e6;
    border-right: 1px solid rgb(54, 5, 5,.05);
}
.linec{
    background-color:#e3e9e6;
}
.treemap_class{
    border: 4px solid rgb(0, 117, 163);
    /* if following is not used it will show the depth */
    
}
.tag_keyword{
    display: inline-block;
    margin:0px 0px;
    border-radius: 2px;
    padding: 3px 8px;
    margin-left: 5px;
    margin-right: 0px;
    font-weight: 600;
    border-radius: 1px;
}
#div_for_p{
    display: inline-block;   
}